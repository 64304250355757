const gamesServerDomain = 'games-server-pro.bethehouse.com';
const gamesServerPort = 443;
const gamesServerHost = gamesServerDomain + ':' + gamesServerPort;

const externalGamesServer = {
  host: 'external.bethehouse.com',

  port: 443,
  ssl: true,
};

const externalGamesServerHost =
  externalGamesServer.host + ':' + externalGamesServer.port;

export const environment = {
  production: true,

  apiUrl: 'https://' + gamesServerHost,

  gamesSocket: {
    host: gamesServerDomain,
    port: gamesServerPort,
    ssl: true,
  },

  externalGamesSocket: externalGamesServer,
  externalGamesApi:
    (externalGamesServer.ssl ? 'https://' : 'http://') +
    externalGamesServerHost,
  currencyApiUrl: 'https://currency-server-pro.bethehouse.com',

  chatServer: {
    host: 'chat-server-pro.bethehouse.com',
    port: 443,
    ssl: true,
  },
  chatServerApiUrl: 'https://chat-server-pro.bethehouse.com',

  /*** WAX MAINNET: ***/
  eos_chainId:
    '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  eos_protocol: 'https',
  eos_host: 'wax.greymass.com',
  eos_port: 443,
  /***/

  recaptchaSiteKey: '6Lc7I_0cAAAAABkBroiSSqOMi1CkL2Gbs0EfLwgX',
};
