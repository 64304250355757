import { EventEmitter, Injectable } from '@angular/core';
import {
  NavigationEnd,
  PreloadingStrategy,
  Route,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';

declare type Loader = () => Observable<any>;

@Injectable()
export class PreloadStrategy implements PreloadingStrategy {
  private loadedModules: { [name: string]: boolean } = {};
  private loaders: { [name: string]: Loader } = {};

  private modulesPreloaded = false;

  private moduleLoaded = new EventEmitter<string>();

  private firstUrl: string;

  constructor(router: Router) {
    const subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.firstUrl == undefined) {
          this.firstUrl = event.url;

          subscription.unsubscribe();
        }
      }
    });
  }

  preload(route: Route, load: Loader): Observable<any> {
    const moduleName = route.data?.name;

    if (!moduleName) {
      return of(null);
    }

    this.loaders[moduleName] = load;

    return of(null);

    /*
    if (this.isFirstUrlTheHomePage) {
      console.log('waiting to preload:', moduleName);

      return of(null);
    } else {
      return this.preloadModule(moduleName);
    }
    */
  }

  preloadAllModules() {
    if (!this.modulesPreloaded) {
      this.modulesPreloaded = true;

      for (const moduleName in this.loaders) {
        this.preloadModule(moduleName);
      }
    }
  }

  private preloadModule(moduleName: string): Observable<any> {
    if (!this.loadedModules[moduleName]) {
      this.loadedModules[moduleName] = true;

      const load = this.loaders[moduleName];

      console.log('preloading module: ' + moduleName);

      const observable = load();

      observable.subscribe(
        () => {},
        () => {},
        () => {
          console.log('module loaded:', moduleName);
          this.moduleLoaded.emit(moduleName);
        }
      );

      return observable;
    } else {
      return of(null);
    }
  }

  get moduleLoadedEvent() {
    return this.moduleLoaded.asObservable();
  }

  /*
  private get isFirstUrlTheHomePage() {
    return this.firstUrl == '/' || this.firstUrl == '/home';
  }
  */
}
