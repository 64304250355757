import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { PreloadStrategy } from './preload-strategy';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./app-page.module').then((m) => m.AppPageModule),
  },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  //scrollPositionRestoration: "enabled",
  preloadingStrategy: PreloadStrategy,
  enableTracing: false,
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  providers: [PreloadStrategy],
  exports: [RouterModule],
})
export class AppRoutingModule {}
